.sidebar{
height: 100%;
/* background-color: pink; */
min-width: 20vw;
border-right: 1px solid lightgray;
display: flex;
flex-direction: column  ;
/* overflow:auto; */

}

.sidebar-header{
    padding: 15px  10px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    /* height: 50px; */
    /* background: #000; */
    /* gap: 1px; */
}
.sidebar-search{
    margin-top: 10px;
    display: flex;
    align-items: center;
    height: 45px;
    padding: 20px   12px;
    width: 100%;
    /* background-color:  white; */
    justify-content: center;
}
.sidebar-searchcontaier{
  
    display: flex;
    background-color: white;
    height: 40px;
    width: 100%;
    /* border: 1px solid black; */
    padding: 3px 10px;

    align-items: center;
    border-radius: 20px;
    /* justify-content: space-between; */
box-shadow: 1px 3px 5px 1px rgba(0,0,0,0.1);

}
.sidebar-searchcontaier >input{
    border: none ;
    outline: none;
    margin-left: 5%;
    width: 100%;
}
.sidebar-searchcontaier > .css-i4bv87-MuiSvgIcon-root  {
    color: rgb(169, 165, 165)    ;
}
.siderbar-chat{
    position: relative;
    padding: 12px 15px;
    width: 100%;
    height: 70px;
    /* background-color: pink; */
    color: black;
    border-bottom: 3px solid lightgray;

    
}
.button-container{
    display: flex;
    gap: 1px;
}
.siderbar-chat:hover{
    background-color: #ededed;
}
.sidebar-chat-section{
    height: 100%;
    width: 100%;
    scroll-margin-bottom: 10px;
    padding-bottom: 56px;
overflow: auto;
overflow-x: hidden;
}

.sidebar-chat-info{
    display: flex;
    align-items: center;
    margin: 4px;
    
}
.sidebar-chat-name{
    margin: 0px 10px;
    margin-top: -12px;
}
.siderbar-chat>p {
position: relative;
bottom: 20px;
left: 54px;
font-size: 12px;

}
.sidebar-newchat{
    width: 100%;
    background-color: whitesmoke;
    padding:  10px;
    cursor: pointer;
    border: none;
    outline: none;
    border: 1px solid lightgray;
    margin: 5px 0px;
    border-radius: 10px;
    font-size: medium;
    font-weight: 600;
    transition-duration: 0.5s;
}
.sidebar-newchat:hover{
    background-color:#ededed;
    
}