.App {
height: 100vh;
display: grid;
place-items: center;
background-color: #dadbd3;
overflow: hidden;

}
#root{
    overflow:hidden;
}
.App a {
    text-decoration: none;
    
}
.app_body{
    display: flex;
    height:90vh;
    width: 80vw;
    background:whitesmoke;
overflow: hidden;
   
}
/* Styles for screens with a maximum width of 768 pixels */
@media only screen and (max-width: 920px) {
   
   .send-button{
display: none;
   }
   .send-button-mob{
    display: inline-block !important;
   }
   .ChatRoom{
    width: 100% !important  
}
.sidebar{
    width: 100% !important;
    
    }
    .chatMessage{
        width: fit-content;
        max-width: 70% !important;
        font-size: 12px !important;
 
    }
    .chat-time{
        left: 0px !important;
    }
    .chat-head{
    display: flex;
    flex-direction: column !important;
    align-items: flex-start !important;
    /* justify-content: none !important; */
    flex: 0.1;
    }
    .chat-head-left-info p {
       
        font-size: xx-small !important;
    }
    .chat-head-right{
        width: 100% !important;
        display: flex;
        margin-top: 2px;

        justify-content: flex-end !important;
    }
    .Arrow-back {
        display: inline-block ;
    }
    .app_body{
        height: 100vh;
        width: 100vw;
        padding-bottom: 30px;
        
    }
    .chat-body{
   
        height: 80vh !important;
    }
  }
