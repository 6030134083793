.ChatRoom{
    height: 100%;
    width: calc(100% - 20vw);
    display: flex;
    flex-direction: column;

}
.chat-head{
    padding: 5px 10px;

/* background: rgb(181, 176, 176); */
display: flex;
align-items: center;
justify-content: space-between;
flex: 0.1;
}

.chat-head-left{
    display: flex;
    align-items: center;
    gap: 9px;
    width: 70%;
}

.chat-head-left-info p {
    color: rgb(92, 92, 92);
    font-size: x-small;
}
.chat-head-right{
    width: 30%;
    display: flex;
    flex: 0.6;
    justify-content: space-evenly;
}
.Arrow-back {
    display: none;
}
.chat-body{
    background-image: url("../bg.png");
    height: 100vh;
    /* background-size: 90%; */
    /* flex: 1; */
    background-repeat: repeat;
    background-position: center;
    overflow-y: auto;
    padding: 20px 10px;
    padding-bottom: 0;
}
.chat-body::-webkit-scrollbar{
    display: none;
}
.chatMessage{
    padding: 10px;
    font-size: 16px;
    border-radius: 10px;
    position: relative;
    width: fit-content;
    max-width: 50%;
    background: #ffffff;
    margin-bottom: 25px;
}

.sender-name{
    position: absolute;
    font-size: x-small;
    font-weight: bold;
    top: -13px;
}
.chat-time{
    position: relative;
    font-size: xx-small; 
    bottom: -10px;
    left: -15px;
}
.chat-receiver{
    margin-left: auto;
    background-color:#dcf8c6 ;
}
.chat-footer{
    padding: 0px 20px;
    padding-bottom: 10px;
    display: flex;
    /* flex: 0.1; */
    width: 100%;
    align-items: center;
    justify-content: space-evenly;
    
}
.chat-footer>form{
    width: 100%;
    height: 100%;
}
.chat-footer>form> input {
    padding: 3px 10px;
    width: 70%;
    margin: 5px;
    flex: 0.8;
    height: 100%;
    outline: none;
    border: none;
    border-radius: 30px;
    box-shadow: 1px 3px 5px 1px rgba(0,0,0,0.1);
}
.send-button{
   /* margin-left: 10%; */
    background-color: whitesmoke;
    padding:  10px;
    cursor: pointer;
    border: none;
    outline: none;
    border: 1px solid lightgray;
    border-radius: 10px;
    font-size: small;
    font-weight: 600;
}
.send-button-mob{
    display: none;
    background-color: whitesmoke;
    padding:  10px;
    cursor: pointer;
    border: none;
    outline: none;
    border: 1px solid lightgray;
    border-radius: 10px;
    font-size: small;
    font-weight: 600;
}