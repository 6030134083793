
*{
  margin: 0;padding: 0;box-sizing: border-box;
}

body.html,#root {
  height: 100vh;width: 100vw;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

