.invalid-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
    width: 80vw;
    text-align: center;
  }
  
  .title {
    font-size: 2em;
    margin-bottom: 20px;
    color: #ff5252; /* Red color */
  }
  
  .message {
    font-size: 1.2em;
    color: #777;
    margin-bottom: 30px;
  }
  
  .illustration {
    max-width: 50%;
    height: auto;
  }
  